export default {
    title: 'Central Units Productivity',
    location: '/business/central-units-productivity',
    tabs: [
        {
            name: 'By Country',
            link: `/business/central-units-productivity`
        },
        {
            name: 'Trend',
            link: `/business/central-units-productivity/:market`
        }
    ]
}
